import { fetchEndpoint, fetchGetPoint } from "@/server/network1";
/**
 * fetchEndpoint
 *  @url    api 接口路径
 *  @data   接口传值
 *  @type   true json传参 /false form传参  ,默认json 可不填
 *  @fun    'GET'/'POST'  默认POST 可不填
 */

//获取字典表相关下拉列
export const getDicDropDown = (data) => fetchGetPoint(
    `/school/dictionary/getDicDropDown/${data.id}`, {}, true, 'GET'
)
//监控列表头部
export const deviceHead = (data) => fetchGetPoint(
    "/school/surveillance/head", { ...data }, true, 'GET'
)
//视频监控列表
export const deviceList = (data) => fetchGetPoint(
    "/school/surveillance/list", { ...data }, true, 'GET'
)

//视频回放
export const backUrl = (data) => fetchGetPoint(
    "/school/surveillance/back/url", { ...data }, true, 'GET'
)
//头部异常学校详情
export const deviceHeadDetail = (data) => fetchGetPoint(
    "/surveillance/head/detail", { ...data }, true, 'GET'
)
//通道列表
export const channelList = (data) => fetchGetPoint(
    "/school/surveillance/channel/list", { ...data }, true, 'GET'
)
//实时监控
export const realUrl = (data) => fetchGetPoint(
    "/school/surveillance/real/url", { ...data }, true, 'GET'
)
//监控列表
export const roundList = (data) => fetchGetPoint(
    "/school/surveillance/round/list", { ...data }, true, 'GET'
)
//同步平台
export const sync = (data) => fetchEndpoint(
    "/school/surveillance/sync", { ...data }, true, 'POST'
)
//获取当前用户信息
export const currentUser = (data) => fetchEndpoint(
    "/school/UserVersifyController/currentUser", { ...data }, true, 'POST'
)

//  获取海康组织树
export const getHkTree = (data) => fetchGetPoint(
    "/school/hikvision/tree", { ...data }, true, 'GET'
)
// 获得当前用户海康配置 ak/sk
export const getHkConfig = (data) => fetchGetPoint(
    "/school/hikvision/config", { ...data }, true, 'GET'
)
